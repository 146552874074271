import {precacheAndRoute} from 'workbox-precaching';

const cacheName = 'app-files-v1';
const filesToCache = ['/'];
const manifest = self.__WB_MANIFEST;

self.addEventListener('install', (event) => {
  event.waitUntil(
    caches.open(cacheName).then((cache) => {
      return cache.addAll(filesToCache);
    })
  );
});

if(manifest){
  precacheAndRoute(manifest);
}

self.addEventListener('activate', function () {
  return self.clients.claim();
});

self.addEventListener('message', (e) => {
  if (e.data.action === 'skipWaiting') {
    self.skipWaiting();
  }
});
